import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../../utils";
import { ElementBodyType, ElementResponseType } from "./element.types";
const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
const adsType = process.env["NEXT_PUBLIC_ADS_TYPE"];

const channelBaseUrl =
  channel === "ads" && adsType === "intl-hotel"
    ? "https://ih.snapptrip.live/international-hotel/api"
    : channel === "ads"
      ? "https://hotelapi.snapptrip.info"
      : "https://hapi.snapptrip.com";

export const ElementUrl = `${channelBaseUrl}/service2/seo/seo/page/element`;

export const ElementService = (
  body: ElementBodyType
): Promise<ElementResponseType> =>
  AxiosNoAuth.post(ElementUrl, body)
    .then(({ data }: AxiosResponse<ElementResponseType>) => data)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("ElementService", ElementUrl, error);
      return null;
    });
