import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { addEventToTracking, USER_TRACKING_EVENTS } from "@website/tracking";
import { BannerMedium, BannerPopup, BannerSlide } from "@website/widget";
import type { NextPage } from "next";
import Head from "next/head";
import Script from "next/script";
import { useEffect } from "react";
import { Features, History } from "../../components";
import globalStyles from "../../styles/global.module.scss";
import SnapptripLogoPersian from "./assets/snapp-trip-logo-persian.svg";
import { useBanner, useSearchHistory } from "./hooks";
import styles from "./international-hotel.module.scss";
import { IntenationalHotelPropertiesType } from "./international-hotel.types";
import { useMarketingBannerService } from "./service";

const Hotel: NextPage<IntenationalHotelPropertiesType> = (properties) => {
  const {
    isError: isErrorMarketingBanner,
    isLoading: isLoadingMarketingBanner,
    marketingBanner
  } = useMarketingBannerService();
  const { seoAdmin } = properties;
  const {
    bannerSlideList,
    bannerPopupItem,
    bannerMediumList,
    bannerPopupVisibility
  } = useBanner({ marketingBanner });
  const { t } = useTranslation();
  const { searchHistory, onHistoryClicked } = useSearchHistory();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;

  useEffect(() => {
    addEventToTracking(USER_TRACKING_EVENTS.HOME_PAGE_VISITED, {
      product: "int-hotel"
    });
  }, []);

  const featuresTitleList = [
    {
      title: t("homepage.inter_hotel.features.online_reserve.title"),
      description: t(
        "homepage.inter_hotel.features.online_reserve.description"
      ),
      Icon: <MaterialIcon name="speed" variant="outlined" />
    },
    {
      title: t("homepage.inter_hotel.features.best_price.title"),
      description: t("homepage.inter_hotel.features.best_price.description"),
      Icon: <MaterialIcon name="payments" variant="outlined" />
    },
    {
      title: t("homepage.inter_hotel.features.support.title"),
      description: t("homepage.inter_hotel.features.support.description"),
      Icon: <MaterialIcon name="support_agent" variant="outlined" />
    },
    {
      title: t("homepage.inter_hotel.features.safe_choice.title"),
      description: t("homepage.inter_hotel.features.safe_choice.description"),
      Icon: <MaterialIcon name="comment" variant="outlined" />
    }
  ];

  const pageTitle =
    seoAdmin?.pageTitle || "رزرو هتل و بلیط هواپیما | اسنپ‌تریپ" || "";
  const description = seoAdmin?.meta?.description || "";
  const title = seoAdmin?.meta?.title || "";
  const canonical = seoAdmin?.meta?.canonical || "";
  const metaTag = seoAdmin?.meta?.metaTag || "";

  return (
    <article className={styles["root"]}>
      <Script id="schema-organization" type="application/ld+json">
        {`{
          "@context": "https://schema.org/",
          "@type": "Organization",
          "url": "${process.env.NEXT_PUBLIC_BASE_URL_B2C}/international-hotel",
          "logo": {
            "@type": "ImageObject",
            "url":  ${SnapptripLogoPersian.src},
            "height": "78",
            "width": "137"
          },
          "name": "اسنپ تریپ",
          "sameAs": [
            "https://www.instagram.com/snapptrip",
            "https://twitter.com/snapptrip",
            "https://www.linkedin.com/company/snapptrip-com"
          ]
        }`}
      </Script>
      <Script id="schema-hotel" type="application/ld+json">
        {`{
          "@context": "https://schema.org/",
          "@type": "LocalBusiness",
          "name": "اسنپ تریپ",
          "address": "Tehran Province, Tehran, Mehrdad, QCCC+977, Iran",
          "image":  ${SnapptripLogoPersian.src},
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "35.7709181",
            "longitude": "51.4180978"
          },
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "validFrom": "",
            "opens": "09:00",
            "closes": "18:00",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday"
            ]
          },
          "telephone": "02196669066",
          "priceRange": "IRR"
        }`}
      </Script>
      <Head>
        <title>{title}</title>
        {description ? <meta content={description} name="description" /> : null}
        {canonical ? <link href={canonical} rel="canonical" /> : null}
        {metaTag && metaTag.length > 0
          ? metaTag.map((item) => {
              const { tagType, content } = item;
              return <meta content={content} key={content} name={tagType} />;
            })
          : null}
        {channel === "jek" ? (
          <meta content="noindex, nofollow" name="robots" />
        ) : (
          <>
            <meta content="website" property="og:type" />
            <meta content="اسنپ تریپ" property="og:site_name" />
            <meta content="fa_IR" property="og:locale" />
            <meta content={SnapptripLogoPersian.src} property="og:image" />
            <meta content="لوگوی اسنپ تریپ" property="og:image:alt" />
            <meta content={description} property="og:description" />
            <meta content={pageTitle} property="og:title" />
            <meta
              content={process.env.NEXT_PUBLIC_BASE_URL_B2C}
              property="og:url"
            />
          </>
        )}
      </Head>
      {searchHistory && searchHistory.length > 0 ? (
        <History list={searchHistory} onClick={onHistoryClicked} />
      ) : null}
      {channel === "jek" ? null : (
        <Features list={featuresTitleList} title={pageTitle} />
      )}
      <section className={globalStyles["container"]}>
        <BannerSlide
          isLoading={isLoadingMarketingBanner}
          list={bannerSlideList}
        />
        <BannerMedium
          isLoading={isLoadingMarketingBanner}
          list={bannerMediumList}
        />
      </section>
      {/* {suggestions && suggestions.length > 0 ? (
          <Suggestions list={suggestions} />
        ) : null} */}
      {/* <PopularCities list={popularCities} /> */}
      {/* {channel === "jek" ? null : (
          <>
            <SpecialContents
              list={specialContents}
              title={t("homepage.inter_hotel.special_contents.title")}
              url="https://www.snapptrip.com/blog/category/%d9%87%d8%aa%d9%84/"
            />
            <SeoContent list={seoAdmin?.seoContents} />
            <Faq list={seoAdmin?.faq} title={t("homepage.inter_hotel.faq.title")} />
            <InboundLinks
              list={seoAdmin?.inboundLinks}
              title={t("homepage.inter_hotel.inbound_links.title")}
            />
          </>
        )} */}
      {bannerPopupItem && bannerPopupVisibility && !isErrorMarketingBanner ? (
        <section>
          <article>
            <BannerPopup item={bannerPopupItem} />
          </article>
        </section>
      ) : null}
      <script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: "تفاوت قیمت هتل با قیمت رزرو از اسنپ‌تریپ چیست؟",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "بدیهی است که با رزرو آنلاین هتل مدت‌ها زودتر از تاریخ سفرمان، انتظار داریم هتلمان را به بهترین قیمت رزرو کنیم. در اسنپ‌تریپ با توجه به میزان کارکرد زیاد اسنپ‌تریپ با هتل‌های ایران، قراردادهای طولانی مدت با هتل‌ها و گارانتی اتاق‌ها، قیمت اتاق‌ها با تخفیف و با قیمتی کمتر از خرید حضوری در هتل ارائه می‌شود تا علاوه بر خرید آنلاین و آسان، با خرید ارزان یک تجربه‌ی خوب از اعتماد به اسنپ‌تریپ برایتان رقم بخورد."
                }
              },
              {
                "@type": "Question",
                name: "شرایط کنسلی و انصراف از رزرو هتل در اسنپ‌تریپ چگونه است؟",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "اگر به هر دلیلی از رفتن به سفر منصرف شوید، می‌توانید رزرو هتلتان را کنسل کنید. امکان کنسلی در هر مرحله از رزرو تا قبل از تحویل اتاق وجود دارد. اما بسته به اینکه زمان درخواست کنسلی شما چند روز قبل از تاریخ ورود باشد یا دقیقا در روز ورود درخواست کنسلی بدهید، میزان جریمه کنسلی‌ای که هتل دریافت خواهد کرد متفاوت است. اسنپ‌تریپ جریمه کنسلی از مهمان دریافت نمی‌کند. جریمه کنسلی از طرف هتل دریافت می‌شود و اسنپ‌تریپ طبق قوانین هتل میزان جریمه کنسلی را از مبلغ رزرو مسافر کسر کرده و به هتل پرداخت می‌کند. از آنجا که قوانین کنسلی و میزان جریمه کنسلی در هتل‌ها متفاوت است، برای اطلاع از مبلغ جریمه کنسلی هتلتان با پشتیبانی اسنپ‌تریپ تماس بگیرید."
                }
              }
            ]
          })
        }}
        type="application/ld+json"
      />
    </article>
  );
};

export default Hotel;
