import { useLocalStorage } from "@website/hooks";
import { useCallback, useEffect, useState } from "react";
import { INTERNATIONAL_HOTEL_BANNER_POPUP_UPDATED_AT_LOCAL_STORAGE_KEY } from "../international-hotel.constant";
import { IntenationalHotelPropertiesType } from "../international-hotel.types";

export const useBanner = (properties: IntenationalHotelPropertiesType) => {
  const { marketingBanner } = properties;
  const [bannerPopupVisibility, setBannerPopupVisibility] = useState(false);
  const { addToLocalStorage, getFromLocalStorage } = useLocalStorage();

  const handleShowingPopup = useCallback(() => {
    const b2UpdatedAtString = getFromLocalStorage(
      INTERNATIONAL_HOTEL_BANNER_POPUP_UPDATED_AT_LOCAL_STORAGE_KEY
    );

    if (
      marketingBanner?.popup?.updatedAt &&
      marketingBanner?.popup?.updatedAt !== b2UpdatedAtString
    ) {
      addToLocalStorage(
        INTERNATIONAL_HOTEL_BANNER_POPUP_UPDATED_AT_LOCAL_STORAGE_KEY,
        marketingBanner?.popup?.updatedAt
      );
      setBannerPopupVisibility(true);
    }
  }, [
    addToLocalStorage,
    getFromLocalStorage,
    marketingBanner?.popup?.updatedAt
  ]);

  useEffect(() => {
    handleShowingPopup();
  }, [handleShowingPopup, marketingBanner]);

  const bannerSlideList = marketingBanner?.slider?.data || null;
  const bannerPopupItem = marketingBanner?.popup?.data?.[0] || null;
  const bannerMediumList = marketingBanner?.medium?.data || [];

  return {
    bannerSlideList,
    bannerPopupItem,
    bannerMediumList,
    bannerPopupVisibility
  };
};
