import { GetStaticProps } from "next";
import { ElementService } from "../../services/base/seo/page/element";
// import { channelTemporary } from "../../utils";

export const getStaticProps: GetStaticProps = async () => {
  try {
    const [seoAdmin] = await Promise.all([
      ElementService({
        path: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/international-hotel`
      }) || null
    ]);

    return {
      props: {
        seoAdmin
      }
    };
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error?.response?.config?.url);
    throw new Error(error);
    // return {
    //   props: {
    //     marketingBanner: [],
    //     seoAdmin: null,
    //     suggestions: []
    //   }
    // };
  }
};
