import { AxiosResponse } from "axios";
import { AxiosNoAuth, findSortBanner } from "../../../../../utils";

import {
  BannerSeperatedType,
  MarketingBannerBodyType,
  MarketingBannerResponseType
} from "./active.types";

export const MarketingBannerUrl = `${process.env.NEXT_PUBLIC_BASE_URL_FP}/banners/banner/active`;

export const MarketingBannerService = (
  body: MarketingBannerBodyType
): Promise<BannerSeperatedType> =>
  AxiosNoAuth.get(MarketingBannerUrl, {
    params: body
  })
    .then(({ data }: AxiosResponse<MarketingBannerResponseType>) => {
      const slider = findSortBanner(data, "slider");
      const popup = findSortBanner(data, "popup");
      const medium = findSortBanner(data, "medium");
      return {
        slider,
        popup,
        medium
      };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(
        "Marketing Banner Service",
        MarketingBannerUrl,
        `channel: ${body?.channel} - page:  ${body?.page} - product:  ${body?.product}`,
        error
      );
      throw new Error("Marketing Banner Service");
    });
