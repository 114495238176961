import {
  getLocalTimeZone,
  parseDateTime,
  today
} from "@internationalized/date";
import { useSessionStorage } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { USER_TRACKING_EVENTS, addEventToTracking } from "@website/tracking";
import { numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { convertDate, stringOfNumbers } from "../../../utils";
import { INTERNATIONAL_HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY } from "../international-hotel.constant";

export const useSearchHistory = () => {
  const [history, setHistory] = useState([]);
  const { getFromSessionStorage, addToSessionStorage } = useSessionStorage();
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { query } = useRouter();

  useEffect(() => {
    const historyString = getFromSessionStorage(
      INTERNATIONAL_HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];

    // remove outdated histories
    const historyWithoutOutdatedItems = history.filter((item) => {
      const startDateObject = parseDateTime(item.startDateTime);
      const todayDateObject = today(getLocalTimeZone());

      if (startDateObject.compare(todayDateObject) < 0) {
        return false;
      }
      return true;
    });

    if (history.length !== historyWithoutOutdatedItems.length) {
      addToSessionStorage(
        INTERNATIONAL_HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(historyWithoutOutdatedItems)
      );
    }

    // prepare histories
    const preparedHistory = historyWithoutOutdatedItems.map((item) => {
      const title =
        item?.destination?.type === "hotel"
          ? `هتل ${item?.destination?.name || item.destination?.state} ${
              item?.destination.city_name_fa
                ? `(${item?.destination.city_name_fa})`
                : ""
            }`
          : `هتل‌های ${item?.destination?.name} ${
              item?.destination.city_name_fa
                ? `(${item?.destination.city_name_fa})`
                : ""
            }`;
      const startDateObject = parseDateTime(item.startDateTime);
      const endDateObject = parseDateTime(item.endDateTime);

      const startDate = `${startDateObject?.year}-${startDateObject?.month
        ?.toString()
        .padStart(2, "0")}-${startDateObject?.day
        ?.toString()
        .padStart(2, "0")}`;
      const endDate = `${endDateObject.year}-${endDateObject?.month
        ?.toString()
        .padStart(2, "0")}-${endDateObject?.day?.toString().padStart(2, "0")}`;

      const startDateObjectLocale = convertDate(
        {
          dateTime: item?.startDateTime,
          year: startDateObject?.year,
          month: startDateObject?.month,
          day: startDateObject?.day
        },
        item.calendarLocale
      );
      const endDateObjectLocale = convertDate(
        {
          dateTime: item.endDateTime,
          year: endDateObject?.year,
          month: endDateObject?.month,
          day: endDateObject?.day
        },
        item.calendarLocale
      );

      const startDateText = `${startDateObjectLocale?.day
        ?.toString()
        .padStart(2, "0")} ${t<string>(
        `calendar.${item.calendarLocale}.monthNames.${
          stringOfNumbers[startDateObjectLocale?.month]
        }`
      )}`;
      const endDateText = `${endDateObjectLocale?.day
        ?.toString()
        .padStart(2, "0")} ${t<string>(
        `calendar.${item.calendarLocale}.monthNames.${
          stringOfNumbers[endDateObjectLocale?.month]
        }`
      )}`;
      const passengerCount = `${item?.passengerCount} ${t(
        "homepage.inter_hotel.main_search.passenger"
      )}`;

      const description = numberConvert(
        `${startDateText} - ${endDateText} / ${passengerCount}`,
        {
          locale: "fa"
        }
      );
      let url = "";

      addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
        date_from: startDate,
        date_to: endDate,
        city: item?.destination.city_name_fa,
        city_id: item?.destination.id,
        product: "int-hotel",
        search_source: "history-cards"
      });

      const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL_B2C;
      if (item?.destination?.type === "hotel") {
        // hotel
        const parameters = {
          ...query,
          date_from: startDate,
          date_to: endDate,
          occupants: item?.occupants,
          source: "recentSearch"
        };

        const urlParameters = new URLSearchParams(parameters);
        url = `${BASE_URL}/international-hotel/${item?.destination
          .city_slug}/${item?.destination
          .hotel_slug}?${urlParameters.toString()}`;
      } else {
        // city
        const parameters = {
          ...query,
          date_from: startDate,
          date_to: endDate,
          occupants: item?.occupants,
          source: "recentSearch"
        };

        const urlParameters = new URLSearchParams(parameters);
        url = `${BASE_URL}/international-hotel/${item?.destination
          .city_slug}?${urlParameters.toString()}`;
      }
      return {
        title,
        description,
        url
      };
    });

    setHistory(preparedHistory);
  }, [addToSessionStorage, channel, getFromSessionStorage, query, t]);

  const onHistoryClicked = useCallback(
    (url: string) => {
      const searchParameters = new URLSearchParams(new URL(url).search);
      const parametersObject = {};
      for (const [key, value] of searchParameters.entries()) {
        parametersObject[key] = value;
      }
      window?.webengage?.track("IH-search-clicked", {
        ...parametersObject,
        channel,
        source: "recentSearch"
      });
      window?.dataLayer?.push({
        event: "IH-search",
        ecommerce: {
          items: [{ ...parametersObject, source: "recentSearch" }]
        }
      });
    },
    [channel]
  );

  return {
    searchHistory: history,
    onHistoryClicked
  };
};
